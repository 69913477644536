<template>
  <el-row :gutter="10" class="mb8">
    <el-button type="primary" @click="handleAdd">新增学时认证</el-button>
  </el-row>
  <el-table v-loading="loading" :data="dataList">
    <!-- <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="${comment}" align="center" prop="id" />
      <el-table-column label="创建人" align="center" prop="createby" /> -->
    <el-table-column label="文件类型" align="center" prop="fileType" />
    <el-table-column label="文件名称" align="center" prop="fileName" />
    <!-- <el-table-column label="文件路径" align="center" prop="filePath" /> -->
    <el-table-column label="学习时间" align="center" prop="learningTime" />
    <el-table-column label="机构名称" align="center" prop="mechanism" />
    <el-table-column label="状态" align="center" prop="status" />
    <el-table-column label="获得学时" align="center" prop="hour" />
    <!-- <el-table-column label="学习内容" align="center" prop="content" /> -->
    <el-table-column
      label="审核通过时间"
      align="center"
      prop="approvedTime"
      width="180"
    >
       <template #default="scope">
        {{ formatDate(scope.row.approvedTime) }}
      </template>
    </el-table-column>
    <el-table-column
      label="操作"
      align="center"
      class-name="small-padding fixed-width"
      width="180"
    >
      <template #default="scope">
        <el-button
          v-if="scope.row.status != '已审批'"
          link
          type="primary"
          size="small"
          @click="handleUpdate(scope.row.id)"
          >修改</el-button
        >
        <el-button
          v-if="scope.row.status != '已审批'"
          link
          type="danger"
          size="small"
          @click="handleDelete(scope.row.id)"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>

  <!-- <el-pagination
    @size-change="sizeChangeHandle"
    @current-change="currentChangeHandle"
    :current-page="pageIndex"
    :page-sizes="[100, 50, 20, 10]"
    :page-size="pageSize"
    :total="totalPage"
    layout="total, sizes, prev, pager, next, jumper"
  >
  </el-pagination> -->

  <el-dialog v-model="open" :title="title">
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="材料类别" prop="fileType">
          <el-select v-model="form.fileType" placeholder="请选择">
            <el-option
              v-for="item in fileTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件名称" prop="fileName">
          <el-input v-model="form.fileName" placeholder="请输入文件名称" />
        </el-form-item>
        <!-- :on-success="handleSuccess"
            :on-progress="handleProgress" -->
        <el-form-item label="文件路径" prop="filePath">
          <el-upload
            action="#"
            :file-list="filesList"
            :auto-upload="true"
            :limit="1"
            :on-exceed="handleExceed"
            :on-progress="handleProgress"
            :before-upload="beforeMaterialList"
            :http-request="
              (file) => {
                return requestMaterialUpload(file);
              }
            "
          >
            <el-button>选择文件</el-button>
            <span>上传一个pdf文件</span>
            <el-progress
              v-if="showProgress"
              :percentage="progressPercent"
              class="progress"
            ></el-progress>
          </el-upload>
        </el-form-item>
        <el-form-item label="机构名称" prop="mechanism">
          <el-input v-model="form.mechanism" placeholder="请输入机构名称" />
        </el-form-item>
        <el-form-item label="学习时间" prop="learningTime">
          <el-input v-model="form.learningTime" placeholder="请输入学习时间" />
        </el-form-item>
        <el-form-item label="学习内容">
          <el-input
            type="textarea"
            v-model="form.content"
            placeholder="请输入学习内容"
          />
        </el-form-item>
      </el-form>
      <div
        class="dialog-footer"
        style="text-align: center; height: 60px; padding: 20px"
      >
        <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";
import { ElMessageBox } from 'element-plus'
import { h } from "vue";
import { ref } from 'vue'
export default {
  data() {
    return {
      // 【请填写功能名称】表格数据
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      loading: true,
      dataList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        createby: null,
        fileType: null,
        fileName: null,
        filePath: null,
        content: null,
        learningTime: null,
        mechanism: null,
        status: "",
        hour: null,
        approvedTime: "",
        page: "",
        limit: "",
      },
      rules: {
        fileName: [
          { required: true, message: "请上输入文件名称", trigger: "blur" },
        ],
        filePath: [
          { required: true, message: "请上传相关文件", trigger: "blur" },
        ],
        mechanism: [{ required: true, message: "请输入机构", trigger: "blur" }],
        learningTime: [
          { required: true, message: "请输入学习时间", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入学习内容", trigger: "blur" },
        ],
      },
      filesList: [],
      title: "请填写申请材料",
      ifupoad: false,
      // 是否显示弹出层
      open: false,
      progressPercent: 0,
      showProgress: false,
      fileTypes: [
        { label: "面授学时认证", value: "1" },
        { label: "网授学时认证", value: "2" },
        { label: "学历学时认证", value: "3" },
        { label: "课题、基金项目学时认证", value: "4" },
        { label: "论文学时认证", value: "5" },
        { label: "学术著作、译著学时认证", value: "6" },
        { label: "活动学时认证", value: "7" },
        { label: "评比、竞赛活动奖项认证", value: "8" },
        { label: "援派工作学时认证", value: "9" },
        {
          label: "重大突发公共卫生事件工作期间提供药品管理与药学服务学时认证",
          value: "10",
        },
      ],
      statuslist: [
        { label: "待审批", value: "1" },
        { label: "退回", value: "2" },
        { label: "已审批", value: "3" },
      ],
      form: {
        id: null,
        createby: null,
        fileType: ref(''),
        fileName: null,
        filePath: null,
        content: null,
        learningTime: null,
        mechanism: null,
        status: null,
        hour: null,
        createTime: null,
        updateTime: null,
        approvedTime: "",
      },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    AhandleUpdate(val){
      console.log(val)
    },
    formatDate(date) {
      if(date == "" || date == null){
        return ""
      }
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    handleUpdate(id){
      this.init(id)
      this.open=true
    
    },
    handleDelete(id) {
      var ids = id ? [id] : [];
      ElMessageBox.confirm("确定要删除这条信息吗?").then(() => {
        this.$http({
          url: this.$http.adornUrl("generator/classhour/delete"),
          method: "post",
          data: this.$http.adornData(ids, false),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            ElMessage({
                  message: h(
                    "p",
                    { style: "line-height: 1; font-size: 14px" },
                    [
                      h("i", { style: "color: teal" }, "删除成功"),
                    ]
                  ),
                });
                this.getList()
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.queryParams.pageSize = val;
      this.queryParams.pageIndex = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.queryParams.pageIndex = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      var query = { page: "1", limit: "100" };
      var that = this;
      // this.queryParams.page = this.pageIndex;
      // this.queryParams.limit = this.pageSize;
      this.$net.listClasshour(query).then((res) => {
        console.log(res)
        this.dataList = res.data.page.list.map(function (item) {
          for (let i of that.fileTypes) {
            if (item.fileType == i.value) {
              item.fileType = i.label;
            }
          }
          return item;
        }).map(function(item){
            for(let i of that.statuslist){
              if(item.status == i.value){
                item.status =i.label
              }  
            }
          return item
        })
        console.log(this.dataList)
        // this.totalPage = res.data.page.totalCount;
        this.loading = false;
      });
    },
    init(id) {
      this.form.id = id || 0;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
        if (this.form.id) {
          this.$http({
            url: this.$http.adornUrl(
              `/generator/classhour/info/${this.form.id}`
            ),
            method: "get",
            params: this.$http.adornParams(),
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.form.createby = data.classhour.createby;
              this.form.fileType = String(data.classhour.fileType)
              this.form.fileName = data.classhour.fileName;
              this.form.filePath = data.classhour.filePath;
              this.form.content = data.classhour.content;
              this.form.learningTime = data.classhour.learningTime;
              this.form.mechanism = data.classhour.mechanism;
              this.form.hour = data.classhour.hour;
              this.form.createTime = data.classhour.createTime;
              this.form.updateTime = data.classhour.updateTime;
              this.form.approvedTime = data.classhour.approvedTime;
            }
          });
        }
      });
    },
    dataFormSubmit() {
      this.form.status = '1'
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.$net.updateClasshour(this.form).then((res) => {
              if (res.data && res.data.code == 0) {
                ElMessage({
                  message: h(
                    "p",
                    { style: "line-height: 1; font-size: 14px" },
                    [
                      h("i", { style: "color: teal" }, "提交成功"),
                    ]
                  ),
                });
                this.open = false;
                this.getList();
              } else {
                ElMessage({
                  message: h(
                    "p",
                    { style: "line-height: 1; font-size: 14px" },
                    [
                      h("span", null, "Message can be "),
                      h("i", { style: "color: teal" }, "提交失败"),
                    ]
                  ),
                });
              }
            });
          } else {
            this.$net.addClasshour(this.form).then((res) => {
              if (res.data && res.data.code == 0) {
                ElMessage({
                  message: h(
                    "p",
                    { style: "line-height: 1; font-size: 14px" },
                    [
                      h("span", null, ""),
                      h("i", { style: "color: teal" }, "提交成功"),
                    ]
                  ),
                });
                this.open = false;
                 this.getList();
              } else {
                ElMessage({
                  message: h(
                    "p",
                    { style: "line-height: 1; font-size: 14px" },
                    [
                      h("span", null, ""),
                      h("i", { style: "color: teal" }, "提交失败"),
                    ]
                  ),
                });
              }
            });
          }
        }
      });
    },
    beforeMaterialList(file) {
      let index = file.name.lastIndexOf(".");
      let extension = file.name.substr(index + 1);
      let extensionList = [
        // 'png',
        // 'PNG',
        // 'jpg',
        // 'JPG',
        // 'jpeg',
        // 'JPEG',
        // 'bmp',
        // 'BMP',
        "pdf",
        "PDF",
      ];
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message({
          message: "文件不可超出20M",
          type: "warning",
          center: true,
        });

        return false;
      } else if (extensionList.indexOf(extension) < 0) {
        this.$message({
          message: "当前文件格式不支持,请上传pdf格式文件",
          type: "error",
          center: true,
        });
        return false;
      }
    },
    handleExceed() {
      this.$message({
        message: "只可以上传一个文件",
        type: "error",
        center: true,
      });
    },
    handleProgress(event, file) {
      this.showProgress = true;
      this.progressPercent = parseInt(file.percent);
    },
    // 覆盖默认的上传行为
    requestMaterialUpload(param) {
      const obj = new FormData();
      //上传文件
      obj.append("file", param.file);
      // obj.append('fileid', row.id)
      // obj.append('dstId', this.busId)
      // obj.append('fl', 'qy')
      obj.append("fileName", param.file.name);
      // 上传文件接口
      this.$net
        .commonUpload(obj)
        .then((res) => {
          this.form.filePath = res.data.filePath;
          this.ifupoad = true;
        })
        .catch(() => {});
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.filesList = [];
      (this.ifupoad = false),
        (this.form = {
          id: null,
          createby: null,
          fileType: null,
          fileName: null,
          filePath: null,
          content: null,
          learningTime: null,
          mechanism: null,
          status: null,
          hour: null,
          createTime: null,
          updateTime: null,
          approvedTime: null,
        });
    },
  },
};
</script>

<style>
</style>